import styled from 'styled-components';

export const Body = styled.div`
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  width: 600px;
  margin: 0 auto;
  padding: 70px 0 220px 0;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 767px) {
    width: 100%;
    padding: 35px;
  }
`;

export const HeaderH3 = styled.div`
  padding: 0;
  margin: 0 0 40px 0;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 35px;
  line-height: 42px;
  text-transform: uppercase;
  color: #6e2b8b;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 20px 0;
  padding: 0;
  text-align: left;
  b {
    font-weight: bold;
    font-family: 'Figtree-Bold', arial, sans-serif;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #6e2b8b;
  transition: all 0.25s ease-out;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: #6e2b8b;
  }
`;
